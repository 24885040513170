<template>
  <div class="detail-modal-container">
    <Dialog
      :dialog="dialogDetailRoom"
      :showCancelButton="false"
      :showOkButton="false"
      :hideHeader="true"
      :centered="true"
      :classList="'detail-room-dialog'"
      @closeDialog="$emit('closeDialog')"
    >
      <template #content>
        <div class="modal-container-room">
          <div class="group-btn-header">
            <b-button
              variant="outline-danger"
              class="mr-10"
              @click="$emit('onRoomWarning', room.id)"
              >警告</b-button
            >
            <b-button
              variant="danger"
              @click="$emit('onRoomForcedEnd', room.id)"
              >強制終了</b-button
            >
          </div>
          <b-row class="room-information">
            <b-col cols="5" class="room-img">
              <b-img
                v-for="(item, index) in room.speakers.slice(0, 8)"
                :key="index"
                src="https://images.pexels.com/photos/842711/pexels-photo-842711.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                class="card-img"
                :class="`card-img-${room.speakers.length}`"
                v-bind="!item.avatar && { blank: true }"
                blank-color="#999"
                :src="item.avatar"
              />
            </b-col>
            <b-col cols="7" class="room-info">
              <p class="room-title">
                {{ room.title }}
              </p>
              <p class="room-stat">
                <span class="mr-10">入室条件： 限定ルーム</span>
                <span>男:</span><span class="mr-10">{{ room.male_min_age_limit }}~{{ room.male_max_age_limit }}歳</span>
                <span>女:</span><span class="mr-10">{{ room.female_min_age_limit }}~{{ room.female_max_age_limit }}歳</span>
                <span
                  ><img
                    src="@/assets/images/icons/male.png"
                    alt="male"
                    class="img-size"
                  />
                  {{ room.male_limit }}
                  <img
                    src="@/assets/images/icons/female.png"
                    alt="female"
                    class="img-size"
                  />
                  {{ room.female_limit }}
                </span>
              </p>
              <span class="room-time">
                {{ formatDate(room.start_at, "yyyy/MM/DD HH:mm") }} ~
                <span v-if="room.status !== 3">{{currentDate}}</span>
                <span v-else>{{formatDate(room.end_at, "yyyy/MM/DD HH:mm")}}</span>
              </span>
              <span>（経過時間：
                <span v-if="room.status !== 3">
                  {{Math.round((new Date(currentDate) - new Date(room.start_at)) / 60000)}}分）
                </span>
                <span v-else>
                  {{Math.round((new Date(room.end_at) - new Date(room.start_at)) / 60000)}}分）
                </span>
              </span>
            </b-col>
          </b-row>
          <p class="room-participants">
            ルーム参加者（合計{{ room.count_member }}名）
          </p>
          <div class="member-room-list">
            <div class="par-list-table">
              <b-table
                :items="room.members"
                :fields="header"
                thead-class="hidden"
              >
                <template
                  v-for="item in header"
                  v-slot:[`head(`+item.key+`)`]="data"
                >
                  <div
                    :style="'width:' + data.field.width"
                    class="header-table"
                    :key="item.key"
                  >
                    <span class="text-center">{{ data.label }}</span>
                  </div>
                </template>
                <template #cell(user_info)="data">
                  <b-avatar
                    size="28px"
                    style="margin-right: 5px"
                    :src="data.item.user.avatar"
                  />
                  {{ data.item.user.username }}
                </template>
                <template #cell(sex)="data">
                  {{ getLabel(data.item.user.sex) }}
                </template>
                <template #cell(creator)="data">
                  <b-badge v-if="data.item.room_owner" variant="primary"
                    >ルーム作成者</b-badge
                  >
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from "@/@core/components/dialog/Dialog.vue";
import { BButton, BTable, BAvatar, BBadge, BImg } from "bootstrap-vue";
import { SEX_TYPE } from "@/constant/constant";
import moment from "moment";

export default {
  name: "RoomDetailModal",
  components: {
    Dialog,
    BButton,
    BTable,
    BAvatar,
    BBadge,
    BImg,
  },
  props: {
    dialogDetailRoom: {
      type: Boolean,
    },
    room: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      header: [
        { label: "col1", key: "user.login_id", value: "", width: "200px" },
        { label: "col2", key: "user_info", value: "", width: "150px" },
        { label: "col3", key: "sex", value: "", width: "150px" },
        { label: "col4", key: "creator", value: "", width: "100px" },
      ],
      sexType: SEX_TYPE,
      currentDate:'',
    };
  },
  created() {
    this.getCurrentDate();
  },
  watch: {
    queryRoute() {
      this.currentDate();
    },
  },
  methods: {
    getCurrentDate() {
      let dateNow = new Date();
// Create a new Date object for Japan time zone, which is UTC+9
      let japanTime = dateNow.toLocaleString('en-US', { timeZone: 'Asia/Tokyo' });
      this.currentDate = moment(japanTime).format('YYYY-MM-DD HH:mm')
    },
    /* <!--@--> (getLabel): Change value to label of sex ------------------------------------------------------------------------- */
    getLabel(value) {
      let label = ''
      Object.keys(this.sexType).forEach((item, index) => {
        if(value == item) {
          label = this.sexType[item]
        }
      })
      return label
    },
  },
};
</script>

<style lang="scss" scoped>
.mr-10 {
  margin-right: 15px;
}
.detail-room-dialog {
  width: 1000px;
}
.modal-container-room {
  .group-btn-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .room-information {
    display: flex;
    .room-img {
      width: 280px;
      height: 150px;
      max-width: 280px;
      max-height: 150px;
      .card-img {
        border-radius: unset;
        object-fit: cover;
        &:hover {
          cursor: pointer;
        }
      }
      .card-img-1 {
        height: 150px;
      }

      .card-img-2,
      .card-img-3,
      .card-img-4 {
        width: calc(250px / 2);
        height: calc(150px / 2);
      }
      .card-img-5,
      .card-img-6 {
        width: calc(250px / 3);
        height: calc(150px / 2);
      }
      .card-img-7,
      .card-img-8 {
        width: calc(250px / 4);
        height: calc(150px / 2);
      }
    }
    .room-info {
      .room-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px;
      }
      .room-stat {
        font-weight: 600;
      }
    }
  }
  .member-room-list {
    margin-top: 20px;
    max-height: 500px;
    overflow-y: auto;
  }
}
.room-participants {
  margin-top: 20px;
}
.img-size {
  width: 12px;
  height: 12px;
}
</style>
