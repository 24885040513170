<template>
  <div class="room-list-container">
    <!--! Header row -->
    <b-row class="row-search">
      <p class="mb-0">全ルーム数<b style="margin: 0 30px 0 10px">{{ pagination.total}}</b></p>
      <FloatInput
        placeholder="ルーム検索"
        :value="querySearch.search_key"
        @change="changeSearch"
        @keyup="handleSearch"
        @click="handleSearch"
      >
        <template #before-input>
          <b-button
            @click="$router.push({ name: 'room-log' })"
            style="height: 38px"
            variant="outline-primary"
            >過去ログ</b-button
          >
        </template>
      </FloatInput>
      <div class="room-status">
        <span class="status-reported"
          ><span class="reported-stt"></span>通報あり</span
        >
        <span class="status-talking"
          ><span class="talking-stt"></span>トーク中</span
        >
      </div>
    </b-row>
    <!-- ! Body card -->
    <b-row class="card-group-container">
      <div class="option-size"><div class="option-size-list">
        <v-select
          v-model="querySearch.size"
          :clearable="false"
          :options="options"
          @input="handleSearch"
        />
      </div></div>
      <b-card
        v-for="(item, index) in list"
        :key="index"
        body-class="card-body-room"
        class="room-card" :class="item.status === 3 ? 'room-card--end' : ''"
      >
        <div class="card-img-group" @click="openDetailRoomDialog(item.id)">
          <b-img
            v-for="(speaker, speakerIndex) in item.speakers"
            :key="speakerIndex"
            class="card-img"
            v-bind="!speaker.avatar && { blank: true }"
            blank-color="#999"
            :class="`card-img-${item.speakers.length}`"
            :src="speaker.avatar"
          />
        </div>
        <div class="card-container-room">
          <div class="title" @click="openDetailRoomDialog(item.id)">
            <div v-if="item.status !== 3"
              :class="item.status === 2 ? 'reported-stt' : 'talking-stt'"
            ></div>
            <p class="title-card">
              {{ item.title }}
            </p>
          </div>
          <div class="info">
            <span class="mr-10">限定ルーム</span>
            <span>男:</span><span class="mr-10">{{ item.male_min_age_limit }}~{{ item.male_max_age_limit }}歳</span>
            <span>女:</span><span class="mr-10">{{ item.female_min_age_limit }}~{{ item.female_max_age_limit }}歳</span><br>
            <span class="mr-10">
              <img
                src="@/assets/images/icons/male.png"
                alt="male"
                class="img-size"
              />
              {{ item.male_limit }}</span
            >
            <span
              ><img
                src="@/assets/images/icons/female.png"
                alt="female"
                class="img-size"
              />{{ item.female_limit }}</span
            >
          </div>
          <div class="stat">
            <div class="time-zone">
              <span class="time-zone-from">{{
                formatDate(item.start_at, "yyyy/MM/DD HH:mm")
              }}</span>
              ~
              <span class="time-zone-to" v-if="item.status !== 3">{{currentDate}}</span>
              <span class="time-zone-to" v-else>{{formatDate(item.end_at, "yyyy/MM/DD HH:mm")}}</span>
            </div>
            <p class="time-count" v-if="item.status !== 3">
              {{Math.round((new Date(currentDate) - new Date(item.start_at)) / 60000)}}分経過
            </p>
            <p class="time-count" v-else>
              {{Math.round((new Date(item.end_at) - new Date(item.start_at)) / 60000)}}分経過
            </p>
          </div>
          <div class="footer-card">
            <b-avatar
              v-if="item.listener_count === 1"
              :src="item.listeners[0].avatar"
              size="28px"
            ></b-avatar>
            <b-avatar-group v-else size="28px" class="avatar-group">
              <b-avatar
                v-for="(listener, index) in item.listeners"
                :key="index"
                :src="listener.avatar"
              ></b-avatar>
              <b-avatar
                v-if="item.listener_count > 6"
                icon="plus"
                variant="success"
              ></b-avatar>
            </b-avatar-group>
            <p class="viewer">{{ item.listener_count }}名視聴</p>
          </div>
        </div>
      </b-card>
      <template v-if="this.list === undefined">
        <p class="text-center">データが見つかりません。</p>
      </template>
    </b-row>
    <!-- ! Pagination section -->
    <b-row>
      <div class="pagination">
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="pagination.total"
          :per-page="pagination.perPage"
          @change="changePage"
        ></b-pagination>
      </div>
    </b-row>
    <!-- ! Dialog section -->
    <DetailModal
      :dialogDetailRoom="dialogDetailRoom"
      :room="room"
      @closeDialog="dialogDetailRoom = false"
      @onRoomWarning="onRoomWarning"
      @onRoomForcedEnd="onRoomForcedEnd"
    ></DetailModal>
  </div>
</template>

<script>
import {
  BRow,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BCard,
  BCardText,
  BButton,
  BAvatar,
  BAvatarGroup,
  BPagination,
  BImg,
} from "bootstrap-vue";
import FloatInput from "@/@core/components/float-input/FloatInput.vue";
import DetailModal from "./DetailModal.vue";
import { apiService } from "@/services/api.service";
import { STATUS } from "@/constant/constant";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";

export default {
  name: "RoomList",
  components: {
    BRow,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BCard,
    BCardText,
    BButton,
    BAvatar,
    BAvatarGroup,
    BPagination,
    BImg,
    FloatInput,
    DetailModal,
    vSelect,
  },
  data() {
    return {
      dialogDetailRoom: false,
      pagination: {
        currentPage: 1,
        total: 0,
        perPage: 25,
      },
      querySearch: {
        page: "",
        size: 25,
        search_key: "",
      },
      options: [10, 25, 50],
      list: [],
      room: null,
      currentDate:'',
    };
  },
  created() {
    this.getCurrentDate();
  },
  mounted() {
    this.onGetRoom();
  },
  computed: {
    queryRoute() {
      return this.$route.query;
    },
  },
  watch: {
    queryRoute() {
      this.onGetRoom();
      this.currentDate();
    },
  },
  methods: {
    getCurrentDate() {
      let dateNow = new Date();
// Create a new Date object for Japan time zone, which is UTC+9
      let japanTime = dateNow.toLocaleString('en-US', { timeZone: 'Asia/Tokyo' });
      this.currentDate = moment(japanTime).format('YYYY-MM-DD HH:mm')
    },
    /* <!--@--> (changeSearch): Handle search change event ------------------------------------------------------------------------- */
    changeSearch(e) {
      this.querySearch.search_key = e;
    },

    /* <!--@--> (handleSearch): Handle search room ------------------------------------------------------------------------- */
    handleSearch() {
      this.querySearch.page = 1;
      this.onGetRoom();
    },

    /* <!--@--> (changeSearch): Handle search change event ------------------------------------------------------------------------- */
    changePage(e) {
      this.querySearch.page = e;
      this.onGetRoom();
    },

    /* <!--!--> Fetch: GET /manage-room (onGetRoom) ------------------------------------------------------------------------- */
    async onGetRoom() {
      this.startLoading();
      try {
        const response = await apiService.get(`manage-room?${this.serialize(this.querySearch)}`);
        this.endLoading();
        if (response.data && response.data.code === STATUS.SUCCESS) {
          this.list = response.data.data.room;
          this.pagination.total = response.data.pagination.total;
          this.pagination.perPage = response.data.pagination.per_page;
        } else if (response.data.code === STATUS.NO_CONTENT) {
          this.list = [];
          // this.pagination.total = 0;
        } else {
          this.notifyError(response.data.message);
        }
      } catch (error) {
        this.endLoading();
        this.notifyError(error.data.message);
      }
    },

    /* <!--!--> Fetch: GET /manage-room/:id (openDetailRoomDialog) ------------------------------------------------------------------------- */
    async openDetailRoomDialog(id) {
      this.startLoading();
      try {
        const response = await apiService.get(`manage-room/${id}`);
        this.endLoading();
        const status = response.data.code;
        if (status === STATUS.SUCCESS) {
          this.room = response.data.data;
          this.dialogDetailRoom = true;
        } else {
          this.notifyError(response.data.message);
        }
      } catch (error) {
        this.endLoading();
        this.notifyError(error);
      }
    },

    /* <!--!--> Fetch: POST /manage-room/warning/:id (onRoomWarning) ------------------------------------------------------------------------- */
    async onRoomWarning(id) {
      this.startLoading();
      try {
        const response = await apiService.post(`/manage-room/warning/${id}`);
        this.endLoading();
        const status = response.data.code;
        if (status === STATUS.SUCCESS) {
          this.onGetRoom();
          this.dialogDetailRoom = false;
          this.notifySuccess(response.data.message);
        } else {
          this.notifyError(response.data.message);
        }
      } catch (error) {
        this.endLoading();
        this.notifyError(error);
      }
    },

    /* <!--!--> Fetch: POST /manage-room/forced-termination/:id (onRoomForcedEnd) ------------------------------------------------------------------------- */
    async onRoomForcedEnd(id) {
      this.startLoading();
      try {
        const response = await apiService.post(
          `/manage-room/forced-termination/${id}`
        );
        this.endLoading();
        const status = response.data.code;
        if (status === STATUS.SUCCESS) {
          this.onGetRoom();
          this.dialogDetailRoom = false;
          this.notifySuccess(STATUS.MESSENGER_END_ROOM_SUCCESS);
        } else {
          this.notifyError(response.data.message);
        }
      } catch (error) {
        this.endLoading();
        this.notifyError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.option-size{
  width: 100%;

  &-list {
    width: 160px;
  }
}
.text-center {
  background-color: #fff;
  width: 100%;
  padding: 20px 0;
}
.card-group-container {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 10px;
  margin-bottom: 30px;
  padding-left: 14px;

  .room-card {
    min-width: 270px;
    max-width: 270px;
    padding: 0 10px;
    min-height: 300px;
    margin-bottom: 0;
    cursor: pointer;
    &--end {
      pointer-events:none;
      background-color: #D9D9D9;
    }
  }

  .card-img-group {
    width: 250px;
    height: 125px;
    max-width: 250px;
    max-height: 125px;
    margin-bottom: 10px;

    .card-img {
      border-radius: unset;
      &:hover {
        cursor: pointer;
      }
    }

    .card-img-1 {
      height: 125px;
    }

    .card-img-2,
    .card-img-3,
    .card-img-4 {
      width: calc(250px / 2);
      height: calc(125px / 2);
    }
    .card-img-5,
    .card-img-6 {
      width: calc(250px / 3);
      height: calc(125px / 2);
    }
    .card-img-7,
    .card-img-8 {
      width: calc(250px / 4);
      height: calc(125px / 2);
    }
  }
}
.mr-10 {
  margin-right: 10px;
}
.img-size {
  width: 12px;
  height: 12px;
}
.row-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 14px;
}
.reported-stt {
  min-width: 15px;
  min-height: 15px;
  background-color: #dc0000;
  border-radius: 10px;
  margin-right: 5px;
}
.talking-stt {
  min-width: 15px;
  min-height: 15px;
  background-color: #26b403;
  border-radius: 10px;
  margin-right: 5px;
}
.room-status {
  display: flex;
  .status-reported {
    margin-right: 15px;
    display: flex;
    align-items: center;
  }

  .status-talking {
    display: flex;
    align-items: center;
  }
}
.card-body-room {
  padding: 10px 0 0;
}
.card-container-room {
  .title {
    display: flex;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    .title-card {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      font-size: 15;
      font-weight: 700;
    }
  }
  .stat {
    margin-top: 10px;
  }
  .footer-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .viewer {
      margin-bottom: 0;
    }
  }
}

.pagination {
  margin: 0 30px 15px 0;
  width: 100%;
  display: flex;
  justify-content: flex-end !important;
  align-items: center;
}

.no-avatar {
  width: 250px;
  height: 125px;
  object-fit: contain;
}
</style>
